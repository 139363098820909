@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;700&display=swap');


:root {
    // Fonts
    --font-poppins: 'Poppins', sans-serif; 

    // Colors
}


